import React from "react"
import ImageCover from "./image-cover"

const Home = ({ content }) => {
  const { home } = content

  return (
    <div className="w-full min-h-screen">
      <ul className="grid items-center row-gap-16 col-gap-16 px-2 py-40 md:px-20 grid-col-auto">
        {home.map((media, id) => (
          <ImageCover key={id} media={media} />
        ))}
      </ul>
    </div>
  )
}

export default Home
