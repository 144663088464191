import React from "react"
import { navigate } from "gatsby"
import { motion, AnimatePresence } from "framer-motion"
import { useSessionStorage } from "react-use"
import cx from "clsx"
import { container, item } from "../components/motion"

const Hero = ({ hero }) => {
  const { intro, colorOption, redirect } = hero
  const { slug = {} } = redirect

  const [isVisible, setVisible] = useSessionStorage("visible", true)

  const handleRedirect = () => {
    navigate(`/${slug.current}`)
    setVisible(false)
  }

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          variants={container}
          initial="in"
          animate="out"
          onAnimationComplete={handleRedirect}
          className={cx(
            "fixed inset-0 z-50 flex items-center justify-center w-full h-screen overflow-hidden select-none",
            colorOption ? "bg-black" : "bg-white"
          )}
        >
          <motion.div variants={item} className="max-w-3xl mx-auto">
            <h1
              className={cx(
                "font-serif text-2xl md:text-6xl text-center whitespace-pre-line",
                colorOption ? "text-white" : "text-black"
              )}
            >
              {intro}
            </h1>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default Hero
