import React from "react"
import { useToggle, useMedia } from "react-use"
import { Link } from "gatsby"
import cx from "clsx"
import ImageRatio from "../components/image-ratio"
import { handleAssetCount } from "../lib/functions"

const ImageCover = ({ media }) => {
  const { slug, title, coverImage, gallery } = media
  const { description, release, asset } = coverImage
  const [hovered, setHovered] = useToggle(false)
  const isWide = useMedia("(min-width: 640px)")

  return (
    <li className="relative" onMouseEnter={setHovered}>
      <Link to={slug.current}>
        {asset && (
          <ImageRatio
            imgStyle={{
              paddingLeft: asset.fluid.aspectRatio < 1 && isWide ? 30 : 0,
              paddingRight: asset.fluid.aspectRatio < 1 && isWide ? 30 : 0,
            }}
            className={cx(
              "transition-opacity duration-200 xs:max-h-img",
              hovered && isWide && "blur opacity-15"
            )}
            src={asset.fluid}
            aspectRatio={asset.fluid.aspectRatio}
            alt={title}
          />
        )}
        {hovered && isWide && (
          <div className="absolute top-0 flex flex-col items-center justify-center w-full h-full p-4">
            <div className="space-y-2 leading-tight text-center">
              <h2 className="font-serif text-lg">{title}</h2>
              <div className="text-sm uppercase">
                <h3>{description}</h3>
                <p>{handleAssetCount(gallery)}</p>
              </div>
              <p className="text-sm">{release}</p>
            </div>
          </div>
        )}
        <h2 className="mt-4 font-serif text-lg text-center capitalize xs:hidden">
          {title}
        </h2>
      </Link>
    </li>
  )
}

export default ImageCover
