import React from "react"
import { graphql } from "gatsby"
import GraphQLError from "../components/graphql-error"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Home from "../components/home"

export const query = graphql`
  query IndexPageQuery {
    site: sanitySite {
      title
      description
      url
      keywords
      image {
        asset {
          _id
        }
      }
    }
    intro: sanityIntro {
      intro
      colorOption
      redirect {
        slug {
          current
        }
      }
    }
    home: sanityHome {
      home {
        id
        title
        slug {
          current
        }
        coverImage {
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid_noBase64
            }
          }
          description
          release(formatString: "YYYY")
        }
        gallery {
          ... on SanityMainImage {
            _key
            _type
          }
          ... on SanityVideo {
            _key
            _type
          }
        }
      }
    }
  }
`

const IndexPage = ({ data, errors }) => {
  if (errors) {
    return (
      <Layout>
        <GraphQLError errors={errors} />
      </Layout>
    )
  }

  const { site, intro, home } = data || {}

  if (!site) return null
  if (!intro) return null
  if (!home) return null

  return (
    <>
      <Hero hero={intro} />
      <Layout>
        <SEO
          title={`${site.title} — ${intro.intro || ""}`}
          description={site.description}
          url={site.url}
          keywords={site.keywords}
          image={site.image}
        />
        <Home content={home} />
      </Layout>
    </>
  )
}

export default IndexPage
