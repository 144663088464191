import React from "react"
import Img from "gatsby-image"

const ImageRatio = ({ src, aspectRatio, ...props }) => {
  let orientation

  if (aspectRatio >= 1) {
    orientation = 4 / 3
  } else {
    orientation = 3 / 4
  }

  return <Img fluid={{ ...src, aspectRatio: orientation }} {...props} />
}

export default ImageRatio
